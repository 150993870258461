<div class="d-flex flex-column h-100 w-100 p-3 bg-form-color overflow-auto">
    <section id="header-page">
        <div class="d-flex text-primary-archipro justify-content-between align-items-center border-bottom pb-2">
            <h4 class="">{{titlePage}} <ng-container *ngIf="showForm && infoFascicolo">({{infoFascicolo.getNumString()}})</ng-container></h4>
        </div>
    </section>
    <section class="dettagli-documento-viewer d-flex flex-column h-100" *ngIf="showForm;else loading">
        <div class="d-flex justify-content-between">
            <span class="title-section-dettaglio">Info fascicolo</span>
            <i (click)="openSection('sectionInfoFascicolo')" class="cursor-pointer icon-{{checkSectionOpened('sectionInfoFascicolo') ? 'up' : 'down'}}-open"></i>
        </div>
        <div class="dropdown-divider"></div>
        <div class="d-flex" *ngIf="checkSectionOpened('sectionInfoFascicolo')">

            <div class="d-flex flex-column w-100">
                <div class="row-dettaglio d-flex" >
                    <span class="label-dettaglio">
                        <b>Titolario:</b>
                    </span>
                    <span class="value-dettaglio d-flex align-items-center">
                        <ng-container *ngIf="infoFascicolo.titolario.getFullTitolarioValue()">{{infoFascicolo.titolario.getFullTitolarioValue()}} - </ng-container>{{infoFascicolo.descrTitolario}}
                    </span>
                </div>

                <div class="row-dettaglio d-flex">
                    <span class="label-dettaglio">
                        <b>Fascicolo:</b>
                    </span>
                    <span class="value-dettaglio d-flex align-items-center">
                      {{infoFascicolo.getNumString()}} ({{infoFascicolo.progrAnno}}/{{infoFascicolo.annoFascicolo}}) aperto il {{infoFascicolo.dataAperturaFascicolo | dataStringTransform}}
                    </span>
                </div>

                <div class="row-dettaglio d-flex" *ngIf="infoFascicolo.oggetto">
                    <span class="label-dettaglio">
                        <b>Oggetto:</b>
                    </span>
                    <span class="value-dettaglio d-flex align-items-center">
                        {{infoFascicolo.oggetto}}
                    </span>
                </div>

                <div class="row-dettaglio d-flex" *ngIf="GLOBALI.PRO_SOGGETTO && infoFascicolo.soggetto">
                    <span class="label-dettaglio">
                        <b>Soggetto:</b>
                    </span>
                    <span class="value-dettaglio d-flex align-items-center">
                        {{infoFascicolo.soggetto}}
                    </span>
                </div>

                <div class="row-dettaglio d-flex">
                    <span class="label-dettaglio">
                        <b>In carico a:</b>
                    </span>
                    <span class="value-dettaglio d-flex align-items-center">
                        {{infoFascicolo.getDescrFascicoloInCarico()}}
                    </span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <span class="title-section-dettaglio">Info competenze</span>
            <i (click)="openSection('sectionCompetenze')" class="cursor-pointer icon-{{checkSectionOpened('sectionCompetenze') ? 'up' : 'down'}}-open"></i>
        </div>
        <div class="dropdown-divider"></div>
        <div class="d-flex flex-column" *ngIf="checkSectionOpened('sectionCompetenze')">
            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Competenza:</b>
                </span>
                <span class="value-dettaglio d-flex align-items-center">
                    {{infoFascicolo.getDescrFascicoloInCarico()}}
                </span>
            </div>

            <div class="row-dettaglio d-flex">
                <span class="label-dettaglio">
                    <b>Conoscenza:</b>
                </span>
                <span class="value-dettaglio d-flex flex-column">
                    <span *ngFor="let cc of elencoCC"> {{cc.descrAgd}} ({{cc.codiceAgd}}) {{cc.descrUo}} ({{cc.codiceUo}})</span>
                </span>
            </div>
        </div>

        <form class="w-100" [formGroup]="trasmissioneForm">
            <fieldset class="w-100" [disabled]="disableForm">
                <div class="d-flex flex-column w-100">
                    <div class="d-flex justify-content-between">
                        <span class="title-section-dettaglio">Nuove competenze e motivo</span>
                        <i (click)="openSection('sectionCompetenzeMotivo')" class="cursor-pointer icon-{{checkSectionOpened('sectionCompetenzeMotivo') ? 'up' : 'down'}}-open"></i>
                    </div>
                    <div class="dropdown-divider"></div>
                    <div class="d-flex flex-column max-with-1400" [ngClass]="{'pl-2' : isTrasmAtti}" *ngIf="checkSectionOpened('sectionCompetenzeMotivo')">
                      <app-competenza-conoscenza *ngIf="!isTrasmAtti; else archivioGenerale"
                      [dynamicModal]="dynamicModal"
                      [optionModal]="optionModal"
                      [competenze]="competenze"
                      [listAgdUoScelti]="listAgdUoScelti"
                      [justChecked]="justChecked"
                      (updateCompetenze)="updateCompetenze($event)"
                      ></app-competenza-conoscenza>

                      <ng-template #archivioGenerale>
                        <!--<div class="d-flex align-items-center file-element spaced-input border bg-white">
                          <b class="icona-competenza-lista">C</b>
                          <div class="ml-2 title-file-upload">
                              <span>Archivio Generale</span>
                          </div>
                        </div>-->
                        <span class="my-1"><b>Competenza: </b> {{competenzaAg.descrizione}} ({{competenzaAg.codicePadre}})</span>
                        <span class="my-2"><b>Motivo: </b>Trasmissione agli atti</span>
                      </ng-template>

                        <div class="d-flex spaced-input">
                            <div class="form-group w-100" [ngClass]="{'text-danger' : checkControlName('motivo')}">
                                <label *ngIf="!isTrasmAtti; else labelNoteAggiuntive">Motivo per competenza*</label>
                                <ng-template #labelNoteAggiuntive><label>Note aggiuntive</label></ng-template>
                                <textarea [readonly]="disabledMotivo" class="form-control" placeholder="" formControlName="motivo" id="motivo" rows="3" [ngClass]="{'border-danger' : checkControlName('motivo')}"></textarea>
                                <small *ngIf="checkControlName('motivo')" id="oggettoRequired" class="form-text">Campo obbligatorio</small>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div class="d-flex spaced-input max-with-1400 pb-2 justify-content-end">
                <button type="button" class="btn bg-primary-archipro text-white mt-3" *ngIf="showTrasmetti" (click)="onSubmit()">Trasmetti</button>
            </div>
        </form>

    </section>
    <ng-template #loading>
        <div class="w-100 h-100">
            <app-loading [text]="'Caricamento in corso'"></app-loading>
        </div>
    </ng-template>
</div>
<app-modal #dynamicModalComponent></app-modal>
