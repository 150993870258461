import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AllegatoObj } from './../../../model/AllegatoObj';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { ResizeService } from './../../../service/resize/resize.service';
import { RequestService } from './../../../service/requestService/request.service';
import { DettaglioDocumentoRequest } from './../../../model/DettaglioDocumentoRequest';
import { ShowObjViewer } from './ShowObjViewer';
import { compareObjByField, Utility } from './../../../Utility/Utility';
import { PrimaryKeyFlussoMail } from 'src/app/model/PrimaryKeyFlussoMail';
import { Router } from '@angular/router';
import { EtichettaObj } from 'src/app/service/StampaEtichettaService/EtichettaObj';
import { DataStringTransformPipe } from 'src/app/pipe/dataStringTransform/data-string-transform.pipe';
import { StampaEtichettaService } from 'src/app/service/StampaEtichettaService/stampa-etichetta.service';
import { PrimaryKeyAllegato } from 'src/app/model/PrimaryKeyAllegato';
import { ScrivaniaComponent } from '../../moduli/scrivania-module/scrivania.component';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { StaticProperty } from 'src/app/Utility/StaticProperty';

declare var $ : any;


@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {

  @Output() azioneEseguita : EventEmitter<null> = new EventEmitter();

  user : any;

  etichettaObj : EtichettaObj = new EtichettaObj();

  objParameterComponent : any = {};
  objAnnulla : any = {};
  isDocNp : boolean = false;

  dettaglioDocumentoRequest : DettaglioDocumentoRequest = new DettaglioDocumentoRequest();

  /**
   * Variabile utilizzata per visualizzare i movimenti i un documento
   */
  showObjViewer : ShowObjViewer = new ShowObjViewer();

  isFirmaDigitale : boolean = false;


  loading : boolean = false;
  errorDocument : boolean = false;
  //Testo nel caso in cui ci sarà un errore di qualche tipo passato dal server
  textError : string = "";

  @Input()
  extViewer : boolean;


  @Input()
  idViewer : string;

  /**
   * Lista degli allegati di tipo AllegatoObj(model anche lato server)
   */
  @Input()
  listaAllegati : Array<AllegatoObj> = [];

  /**
   * Oggetto che viene passato dalla lista al click
   */
  @Input()
  documentoViewer : any;

  /**
   * Oggetto preso da getDettaglioDocumento (vedere se fare la model di questo oggetto)
   */
  documentoInfo : any = {security : {}};

  /**
   * Lista che serve a costruire la lista delle conoscenze sulle infomrazioni prese da getDettaglioDocumento
   */
  elencoCC : any = [];

  /**
   * In realtà non è un oggetto com'era in precedenza ma è diventata una lista per gestire le multicompetenze
   */
  objCompetenza : any = [];

  /**
   * Oggetto dell'allegato che viene visualizzato in quel moomento (di tipo AllegatoObj)
   */
  allegatoVisualizzato : AllegatoObj = new AllegatoObj() ;
  allegatoEmail : AllegatoObj = new AllegatoObj() ;

  /**
   * Variabile utilizzata per passare dal component dei flussi di interoperabilità a quello del dettaglio l'oggetto
   * della mail da visualizzare nel viewer
   */
   pkFlussoMail : PrimaryKeyFlussoMail = new PrimaryKeyFlussoMail();


  size : SCREEN_SIZE;

  @Input() reloadList : () => void;

  constructor(private resizeSvc : ResizeService,
    private requestService : RequestService,
    private router : Router,
    private stampaEtichettaService : StampaEtichettaService,
    public globalService : GlobalService) {
    this.size = this.resizeSvc.onResize();
      this.user = Utility.localStorageGet(StaticProperty.UTENTE_SESSIONE);
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }

  ngOnInit(): void {
    this.showObjViewer = new ShowObjViewer();

    if(Utility.checkObj(this.documentoViewer) && !Utility.checkObjIsEmpty(this.documentoViewer) && this.documentoViewer.documenti.length > 0)
    {
      this.listaAllegati = this.documentoViewer.documenti;
      this.allegatoVisualizzato = this.listaAllegati[0];
      this.getInfoAllegato(this.allegatoVisualizzato);

      this.isDocNp = this.documentoViewer.pk.annoProt < 0;
    }
    //prendo i dettagli del documento

    this.getDettaglioDocumento();
  }

  get showStampaGenerica(){
    return this.globalService?.GLOBAL?.PRO_TIPO_PRINTER == "GENERICA";
  }

  getInfoAllegato(allegato){
    this.errorDocument = false;
    this.allegatoVisualizzato = allegato;
    let pkAllegato = allegato.pk
    this.loading = true;

    if(this.allegatoVisualizzato.pk.progr == -1) //vuol dire che è una stampa
    {
      setTimeout(() => {this.loading = false}, 200);
    }
    else{
      this.requestService.postRequest("getDocumentoProtocollo", pkAllegato,
      (response) => {
        this.allegatoVisualizzato.signer = response.obj.signer;
        this.allegatoVisualizzato.url = response.obj.url;
        this.allegatoVisualizzato.converted = response.obj.converted;

        //Visto che se non è firma digitale il firmatario è solamente 1 e avrà solamente il campo descrizione compilato,
        // se signer ha più di 1 elemento, controllo solamente se il primo elemento ha nome, cognome o cf compilati.
        //Se sono compilati, allora la firma è digitale, altrimenti no.
        if(this.allegatoVisualizzato.signer != null && this.allegatoVisualizzato.signer.length > 0)
        {
          this.isFirmaDigitale = this.allegatoVisualizzato.signer[0].descrizione == null;
        }

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.errorDocument = true;
        this.textError = Utility.getMessageByCode(error.message);
      }
    );
    }


  }

  isZip(fileName : string){
    return fileName?.split(".").slice(-1)[0].toLowerCase() == "zip";
  }

  /**
   * Funzione utilizzata per prendere i dettagli del documento (non è lo stesso dei dettagli dell'allegato)
   */
  getDettaglioDocumento(){
    if(Utility.checkObj(this.allegatoVisualizzato.pk))
    {
      this.dettaglioDocumentoRequest.setAnnoProtocollo(this.allegatoVisualizzato.pk.annoProt);
      this.dettaglioDocumentoRequest.setNumeroProtocollo(this.allegatoVisualizzato.pk.numProt);
      this.requestService.postRequest("getDettaglioDocumento", this.dettaglioDocumentoRequest,
        (response) => {
          this.documentoInfo = response.obj.Documento;

          this.objParameterComponent.dettaglioDocumentoRequest = this.dettaglioDocumentoRequest;
          this.objParameterComponent.docNumString = this.documentoInfo.docNumString;

          this.buildObjCompetenezaAndListCC();

          this.reloadList ? this.reloadList() : null;
        }
      );
    }
  }

  buildObjCompetenezaAndListCC()
  {
    this.elencoCC = [];
    this.objCompetenza = [];

    this.objCompetenza = this.documentoInfo.competenzaDoc.filter(element => element.flagDestCC != "C").sort((a : string, b : string) => compareObjByField(a, b, 'flagDestCC'));
    this.elencoCC = this.documentoInfo.competenzaDoc.filter(element => element.flagDestCC  == "C");

  }

  openMailViewer = (pkFlussoMail : PrimaryKeyFlussoMail) : void => {
    this.pkFlussoMail = pkFlussoMail;
    this.showObjViewer.setDettaglioEmail();
  }

  trasmettiDocumento() : void{
    $('#' + this.idViewer).modal('toggle');
    this.router.navigateByUrl('/modulo/gestioneDocumenti/trasmettiDocumento', { state: {numProt : this.documentoInfo.pk.numProt, annoProt : this.documentoInfo.pk.annoProt} });
  }



  /******************da dettaglio doc********************** */
  modificaProtocollo() : void{
    $('#' + this.idViewer).modal('toggle');
    this.router.navigateByUrl('/modulo/gestioneDocumenti/modificaProtocollo', { state: {numProt : this.documentoInfo.pk.numProt, annoProt : this.documentoInfo.pk.annoProt} });
  }


  protocollaDaNp() : void{
    $('#' + this.idViewer).modal('toggle');
    this.router.navigateByUrl('/modulo/gestioneDocumenti/protocollaDaNp', { state: {numProt : this.documentoInfo.pk.numProt, annoProt : this.documentoInfo.pk.annoProt} });
  }


  /****************** Per iter approvazione documento ********************** */
  get showApprovaDoc(){
    return (ScrivaniaComponent.getAbilitatoByNameFunction("documentiInLavorazione") || ScrivaniaComponent.getAbilitatoByNameFunction("documentiDaApprovare"))
    &&  this.documentoInfo?.pk?.annoProt < 0;
  }

  approva(){
    this.requestService.postRequest("aggiornaStatoNP", {
        numProt : this.documentoInfo.pk.numProt,
        annoProt : this.documentoInfo.pk.annoProt,
        statoNP : 2
      },
      success => {
        this.azioneEseguita.emit(this.documentoInfo.docNumString);
        setTimeout(()=>{$('#viewerDocumentiModal').modal("toggle");}, 300)
      }
    )
  }
  /****************** Per iter approvazione documento ********************** */





  annullaProtocollo(){
    this.objAnnulla.annoProt = this.documentoInfo.annoProtocollo;
    this.objAnnulla.numProt = this.documentoInfo.numeroProtocollo;
    this.objAnnulla.motivoAnnullamento = this.documentoInfo.motivoAnnullato;
    this.showObjViewer.setAnnullaProtocollo();
  }


  goToMultifascicolazione() : void{
    $('#' + this.idViewer).modal('toggle');
    this.router.navigateByUrl('/modulo/gestioneDocumenti/multiFascicolazione', { state: {numProt : this.documentoInfo.pk.numProt, annoProt : this.documentoInfo.pk.annoProt} });
  }

  /**
   * Metodo che inizializza l'oggetto che serve a costruire l'etichetta e poi chiama il servizio di stampa;
   */
  stampaEtichetta() {
    this.etichettaObj = this.buildEtichettaObj();
    this.stampaEtichettaService.avviaStampa(this.etichettaObj);
  }

  buildEtichettaObj(){
    let etichetta : EtichettaObj = new EtichettaObj();

    etichetta.numProt = this.documentoInfo.pk.numProt;
    etichetta.annoProt = this.documentoInfo.pk.annoProt;
    etichetta.numProtUff = this.documentoInfo.numProtUff;
    etichetta.annoProtUff = this.documentoInfo.annoProtUff;
    etichetta.siglaUff = this.documentoInfo.siglaUff;
    etichetta.dataProt = new DataStringTransformPipe().transform(this.documentoInfo.dataProt);
    etichetta.setTitolarioFromString(this.documentoInfo.titolario);
    etichetta.numFascicolo =  this.documentoInfo.numFascicolo;
    etichetta.annoFascicolo =  this.documentoInfo.annoFascicolo;
    etichetta.flusso =  this.documentoInfo.provDest == 'E' ? 'Entrata' : (this.documentoInfo.provDest == 'U' ? 'Uscita' : "Interno");
    etichetta.inCaricoA = this.objCompetenza.codiceAgd;

    return etichetta;
  }


  async stampaEtichettaGenericaPerAllegato(allegato : AllegatoObj)
  {
    let url;
    this.etichettaObj = this.buildEtichettaObj();
    this.etichettaObj.progr = allegato.pk.progr;

    if(!allegato.stampaGenericaObj && allegato.pk.progr != -1)
      url = await this.stampaEtichettaService.avviaStampa(this.etichettaObj);
    else if(allegato.pk.progr == -1) //Significa che l'allegato che si sta tentando di chiamare è uguale a quello passato alla funzione
      url = allegato.url
    else
      url = allegato.stampaGenericaObj.url;

    if(url){
      this.loading = true;
      let descrizione ="Etichetta " + (Number(allegato.pk.progr) == 0 ? "file principale" : "allegato") + " del documento "

      if(this.globalService.GLOBAL.PRO_PROG_UFFICIO)
      {
        descrizione += this.etichettaObj.numProtUff + "/" + Math.abs(this.etichettaObj.annoProtUff) + " " + this.etichettaObj.siglaUff;
      }
      else
      {
        descrizione += this.etichettaObj.numProt + "/" + Math.abs(this.etichettaObj.annoProt);
      }

      //metto il timeout per dare il tempo al framework di pdf wiewer di riaggiornarsi
      setTimeout(()=>{
        this.allegatoVisualizzato = new AllegatoObj();
        this.allegatoVisualizzato.url = url;
        this.allegatoVisualizzato.pk = new PrimaryKeyAllegato({progr : -1});
        this.allegatoVisualizzato.descrizione = descrizione;

        if(!allegato.stampaGenericaObj)
          allegato.stampaGenericaObj = Utility.deepCopyObject(this.allegatoVisualizzato);

        this.showObjViewer.setAllegato();
        this.loading = false;
      }, 200)
    }
  }

  showAllegatoEmailSelezionato(allegatoEmail : AllegatoObj){
    setTimeout(() => {
      this.allegatoEmail = allegatoEmail;
      this.showObjViewer.setShowAllegatoEmail();
    }, 200);

  }
}
