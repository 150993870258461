import { AllegatoObj } from "./AllegatoObj";
import { PrimaryKey } from "./PrimaryKey";

export class DatiEml extends PrimaryKey{
  oggetto : string;
  corpo : string;
  mittente : string;
  destinatariA : string[] = [];
  destinatariCc : string[] = [];
  metadati : AllegatoObj[] = [];
  stringDestinatariA : string = "";
  stringDestinatariCc : string = "";

  pattern : RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;


  constructor(obj? : any){
    super(obj);

    if(obj){
      this.oggetto = obj.oggetto;
      this.corpo = obj.corpo;
      this.mittente = obj.mittente;
      this.destinatariA = obj.destinatariA;
      this.destinatariCc = obj.destinatariCc;
      this.metadati = obj.metadati;
    }
  }

  /**
   * Metodo interno che costruisce avendo numero e anno protocollo
   * l'oggetto di default della mail
   */
  buildDefaultOggetto(docNumString : string) : void{
    if(docNumString){
      this.oggetto = "Invio protocollo N. " +  docNumString;
    }else if(!!this.numProt && !!this.annoProt)
    {
      this.oggetto = "Invio protocollo N. " +  this.numProt + " / " + this.annoProt;
    }
  }

  /**
   * Metodo interno che costruisce avendo numero e anno protocollo
   * il corpo di default della mail
   */
  buildDefaultCorpo(oggettoProt : string, notificante : string, docNumString? : string) : void{

    let accapo = "\r\n\r\n";

    if(docNumString)
    {
      this.corpo = "Salve,"+ accapo +"Le è stato notificato il protocollo N. " + docNumString
      + ""+ accapo +"" + oggettoProt + ""+ accapo +"" + "Notifica inviata da: " + notificante;
    }
    else if(!!this.numProt && !!this.annoProt)
    {
      this.corpo = "Salve,"+ accapo +"Le è stato notificato il protocollo N. " +  this.numProt + " / " + this.annoProt
        + ""+ accapo +"" + oggettoProt + ""+ accapo +"" + "Notifica inviata da: " + notificante;
    }
  }


  /**
   * Metodo utilizzato per costruire la lista degli allegati da json
   */
  buildListAllegati(list : any[]) : void{
    this.metadati = list?.map(element => new AllegatoObj(element));
  }

  /**
   * Metodo che settati destinatarA e DestinatariCc costruisce le stringhe da vedere e modificare a video
   */
  buildStringDestinatari() : void{
    this.destinatariA?.forEach(element => {
      this.stringDestinatariA += element.trim() + "; "
    })

    this.destinatariCc?.forEach(element => {
      this.stringDestinatariCc += element.trim() + "; "
    })
  }

  /**
   * Metodo inverso di buildStringDestinatari():
   * Date le stringe dei destinatari inserite dagli utenti, vengono costruite le liste
   * dei destinatari
   */
  buildDestinatri() : void{
    this.destinatariA = this.splitAndFilterNotEmptyStringEmail(this.stringDestinatariA);
    this.destinatariCc = this.splitAndFilterNotEmptyStringEmail(this.stringDestinatariCc);
  }

  /**
   * Funzione che splitta per ; e effettua il trim degli elementi spittati.
   * Inoltre filtra gli elementi e non ritorna stringhe vuote all'iterno dell'array
   * @param stringDest : Stringa da splittare
   * @returns : array delle email splittate dalla stringa
   */
  splitAndFilterNotEmptyStringEmail(stringDest : string) : string[] {
    return stringDest?.split(";").map(element => {
      return element?.trim();
    })
    .filter(element => !!element);
    //.filter(element => this.pattern.test(element));
  }

  checkEmail() : boolean | any {
    let stringDestA : string[];
    let stringDestCc : string[];

    stringDestA = this.destinatariA.filter(element => !this.pattern.test(element));
    stringDestCc = this.destinatariCc.filter(element => !this.pattern.test(element));

    if(stringDestA?.length == 0 && stringDestCc?.length == 0){
      return false;
    }
    else
    {
      return {stringDestA : stringDestA, stringDestCc : stringDestCc}
    }

  }

}
