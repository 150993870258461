<app-loading *ngIf="searchInProgress; else showEstratti"></app-loading>

<ng-template #showEstratti>
  <table class="table w-100 table-striped">
    <thead>
      <tr class="text-primary-archipro">
        <th scope="col">Tit / Num / Anno</th>
        <th scope="col">Data</th>
        <th scope="col"><span class="d-flex cursor-pointer"><span *ngIf="globalService.GLOBAL.PRO_SOGGETTO">Soggetto - </span>Oggetto </span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let fascicolo of listaFascicoliEstratti">
        <td>{{fascicolo.getFullTitolarioAndFascicoloString()}}</td>
        <td>{{fascicolo.dataAperturaFascicolo | dataStringTransform}}</td>
        <td>
          <div class="d-flex flex-column">
            <div class="cut-text" title="{{fascicolo.soggetto}}" class="cut-text" *ngIf="globalService.GLOBAL.PRO_SOGGETTO">{{fascicolo.soggetto}}</div>
            <div class="cut-text" title="{{fascicolo.oggetto}}" class="cut-text">{{fascicolo.oggetto}} </div>
        </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

