<div class="navbar border-bottom">
    <div class="d-flex voci-menu spaced-input">

        <div class="position-relative cursor-pointer"  (click)="toggleSidebar()">
            <i class="icon-menu "></i>
            <i class="icon-{{getShowSidebar() ? 'left' : 'right' }}-open position-absolute position-pedice-menu"></i>
        </div>

        <div class="d-flex spaced-input align-items-center">
            <span class="text-primary-archipro font-weight-bold active pl-1">Protocollo</span>
            <span *ngIf="globalService?.GLOBAL && globalService?.GLOBAL.PRO_URL_PROCEDO" class="text-primary-archipro cursor-pointer" (click)="goToProcedo()">Pratiche</span>
        </div>

    </div>
    <div class="d-flex pulsanti">
        <span *ngIf="false" (click)="switchArchiPRO()" class="cursor-pointer">Vai alla versione attuale</span>
        <div class="dropdown">
            <span id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="icon-user" title="Info utente"></i>
            </span>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <!--<h6 class="dropdown-header">Il mio profilo</h6>-->
                <div *ngIf="user" class="dropdown-information d-flex flex-column text-primary-archipro">

                    <div class="righe-info d-flex justify-content-center">
                        <div class="w-100 d-flex justify-content-center">
                            <i class="icon-user photo-profile"></i>
                        </div>
                    </div>

                    <div class="righe-info d-flex justify-content-center">
                        <!--<span><b>Nome:</b> {{user.nome}}</span>
                        <span><b>Cognome:</b> {{user.cognome}}</span>-->
                        <h4>{{user.nome}} {{user.cognome}}</h4>
                    </div>
                    <div class="righe-info d-flex flex-column">
                        <span><b>Matricola:</b> {{user.matricola}}</span>
                        <span><b>{{getLabelAgd()}}:</b>  {{user.descrAgd}} ({{user.codAD}})</span>
                        <span><b>{{getLabelUo()}}:</b>  {{user.descrUo}} ({{user.codUO}})</span>
                    </div>
                    <div class="righe-info d-flex justify-content-between">

                    </div>


                </div>
                <div>
                  <div class="dropdown-divider"></div>
                  <span *ngIf="!globalService?.GLOBAL?.PRO_LDAP" class="dropdown-item cursor-pointer" (click)="openCambioPassword()">Cambio password</span>
              </div>


            </div>
        </div>
        <i (click)="logout()" class="icon-logout" title="Logout"></i>
    </div>
</div>

 <!-- Modale -->
 <app-modal #dynamicModalComponentNavbar [idModal]="'changePwd'"></app-modal>
