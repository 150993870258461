import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { RequestService } from 'src/app/service/requestService/request.service';
import { element } from 'protractor';
import { getLabelAgd, getLabelUo } from 'src/app/Utility/Utility';

@Component({
  selector: 'app-elenco-agd-uo-modal',
  templateUrl: './elenco-agd-uo-modal.component.html',
  styleUrls: ['./elenco-agd-uo-modal.component.scss']
})

export class ElencoAgdUoModalComponent implements OnInit {

  @Input()
  objParameterComponent : any;

  filtroAgdUo : FiltroAgdUo = new FiltroAgdUo();

  listAgdUo : any = [];
  addAgdUoScelto : (agd:any) => void;

  getLabelAgd : () => string = getLabelAgd;
  getLabelUo : () => string = getLabelUo;
  riportaAgdUo : (agdUoScelto : any) => any;

  constructor(private requestService : RequestService) {

   }

  ngOnInit(): void {
    console.log("this.objParameterComponent", this.objParameterComponent)
    console.log("ciacascauchusabcuiyabsyubca", this.riportaAgdUo)
    if(!!this.objParameterComponent && this.objParameterComponent.riportaAgdUo)
    {
      this.riportaAgdUo = this.objParameterComponent.riportaAgdUo;

    }
    this.cercaListAgdUo();
  }

  checkAgdUoSelezionati(elementToCheck : any) : void
  {
    /*se l'elemento da controllare ha figli, vuol dire che l'elemento deve essere
    controllato solo al primo livello e non deve cercare nei figli*/
    if(elementToCheck.hasOwnProperty("children")){
        this.listAgdUo.forEach(x => {
            if(x.codice == elementToCheck.codice)
            {
                x.activeC = elementToCheck.activeC;
                x.activeCC = elementToCheck.activeCC;
            }
        });
    }
    else
    {
        let element : any = {};
        this.listAgdUo.forEach(x => {
            if(x.codice == elementToCheck.codicePadre)
            {
                element = x;

            }
        });

        if(element.hasOwnProperty("children") && element.children != null && element.children.length > 0)
        {
            element.children.forEach(x => {
                if(x.codice == elementToCheck.codice)
                {
                    x.activeC = elementToCheck.activeC;
                    x.activeCC = elementToCheck.activeCC;
                }
            });
        }
    }
  }


  cercaListAgdUo() : void
  {
    this.requestService.postRequest("getElencoAgdUo", this.filtroAgdUo,
        (response) => {
            this.listAgdUo = response.obj.list;

            if(this.objParameterComponent != null)
            {
                this.addAgdUoScelto = this.objParameterComponent.addAgdUoScelto;
                if(this.objParameterComponent.listAgdUoScelti.length > 0)
                {
                    this.objParameterComponent.listAgdUoScelti.forEach(x => {
                        this.checkAgdUoSelezionati(x);
                    });
                }
            }
        }
    )
  }

}


/**
 * Classe creata per oggetto filtro da mandare al server (vedere come modificarlo per le chiamate effettive)
 */
class FiltroAgdUo{
    codAgd : number;
    codUO : number;
    cognome : string;
    descrAgd : string;
    descrUO : string;
}
