/**
 * Classe utilizzata per la gestione del breadcrumb nel viewer
 */
export class ShowObjViewer{
    showAllegato : boolean = true;
    showMovimenti : boolean = false;
    showDettaglioDoc : boolean = false;
    showDatiFirma : boolean = false;
    showRiferimenti : boolean = false;
    showFlussi : boolean = false;
    showDettaglioEmail : boolean = false;
    showAnnullaProtocollo : boolean = false;
    showInvioEmail : boolean = false;
    showAllegatoEmail : boolean = false;
    showPubblicazioneAlbo : boolean = false;

    setAllegato(){
        this.showAllegato = true;
        this.showMovimenti = false;
        this.showDettaglioDoc = false;
        this.showDatiFirma = false;
        this.showRiferimenti = false;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false;
        this.showInvioEmail = false;
        this.showAllegatoEmail = false;
        this.showPubblicazioneAlbo = false;
    }

    setMovimenti(){
        this.showAllegato = false;
        this.showMovimenti = true;
        this.showDettaglioDoc = false;
        this.showDatiFirma = false;
        this.showRiferimenti = false;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false;
        this.showInvioEmail = false;
        this.showAllegatoEmail = false;
        this.showPubblicazioneAlbo = false;
    }

    setDettaglioDoc(){
        this.showAllegato = false;
        this.showMovimenti = false;
        this.showDettaglioDoc = true;
        this.showDatiFirma = false;
        this.showRiferimenti = false;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false;
        this.showInvioEmail = false;
        this.showAllegatoEmail = false;
        this.showPubblicazioneAlbo = false;
    }

    setDatiFirma(){
        this.showAllegato = false;
        this.showMovimenti = false;
        this.showDettaglioDoc = false;
        this.showDatiFirma = true;
        this.showRiferimenti = false;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false;
        this.showInvioEmail = false;
        this.showAllegatoEmail = false;
        this.showPubblicazioneAlbo = false;
    }

    setRiferimenti(){
        this.showAllegato = false;
        this.showMovimenti = false;
        this.showDettaglioDoc = false;
        this.showDatiFirma = false;
        this.showRiferimenti = true;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false;
        this.showInvioEmail = false;
        this.showAllegatoEmail = false;
        this.showPubblicazioneAlbo = false;
    }

    setFlussi(){
        this.showAllegato = false;
        this.showMovimenti = false;
        this.showDettaglioDoc = false;
        this.showDatiFirma = false;
        this.showRiferimenti = false;
        this.showFlussi = true;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = false;
        this.showInvioEmail = false;
        this.showAllegatoEmail = false;
        this.showPubblicazioneAlbo = false;
    }

    setAnnullaProtocollo(){
        this.showAllegato = false;
        this.showMovimenti = false;
        this.showDettaglioDoc = false;
        this.showDatiFirma = false;
        this.showRiferimenti = false;
        this.showFlussi = false;
        this.showDettaglioEmail = false;
        this.showAnnullaProtocollo = true;
        this.showInvioEmail = false;
        this.showAllegatoEmail = false;
        this.showPubblicazioneAlbo = false;
    }

    setShowInvioEmail(){
      this.showAllegato = false;
      this.showMovimenti = false;
      this.showDettaglioDoc = false;
      this.showDatiFirma = false;
      this.showRiferimenti = false;
      this.showFlussi = false;
      this.showDettaglioEmail = false;
      this.showAnnullaProtocollo = false;
      this.showInvioEmail = true;
      this.showAllegatoEmail = false;
      this.showPubblicazioneAlbo = false;
    }

    setShowAllegatoEmail(){
      this.showAllegato = false;
      this.showMovimenti = false;
      this.showDettaglioDoc = false;
      this.showDatiFirma = false;
      this.showRiferimenti = false;
      this.showFlussi = false;
      this.showDettaglioEmail = false;
      this.showAnnullaProtocollo = false;
      this.showInvioEmail = true;
      this.showAllegatoEmail = true;
      this.showPubblicazioneAlbo = false;
    }

    setPubblicazioneAlbo() {
      this.showAllegato = false;
      this.showMovimenti = false;
      this.showDettaglioDoc = false;
      this.showDatiFirma = false;
      this.showRiferimenti = false;
      this.showFlussi = false;
      this.showDettaglioEmail = false;
      this.showAnnullaProtocollo = false;
      this.showInvioEmail = false;
      this.showAllegatoEmail = false;
      this.showPubblicazioneAlbo = true;
    }

    /**
     * In questo caso il set del dettaglio email dipende dal valore di showFlussi
     * perchè il dettaglio delle mail deve essere visualizzato solo se viene chiamato dai flussi,
     * questo vuol dire che quando viene chiamato questo metodo showDettaglioEmail viene settato a prescindere a true
     */
    setDettaglioEmail(){
        this.showDettaglioEmail = this.showFlussi;
    }



}
