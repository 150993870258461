import { Component, Input, OnInit, HostListener } from '@angular/core';
import { RequestService } from './../../../../service/requestService/request.service';
import { getLabelAgd, getLabelUo } from './../../../../Utility/Utility';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { DettaglioDocumentoRequest } from './../../../../model/DettaglioDocumentoRequest';
import { ResizeService } from './../../../../service/resize/resize.service';

@Component({
  selector: 'app-elenco-movimenti',
  templateUrl: './elenco-movimenti.component.html',
  styleUrls: ['./elenco-movimenti.component.scss']
})
export class ElencoMovimentiComponent implements OnInit {

  getLabelAgd : () => string = getLabelAgd;
  getLabelUo : () => string = getLabelUo;

  size : SCREEN_SIZE;

  @Input()
  objParameterComponent : any;

  @Input()
  dettaglioDocumentoRequest : DettaglioDocumentoRequest;

  @Input()
  fascicolo : any;

  @Input() fascicoliMode : boolean = false;

  docNumString : string = "";

  tipoElencoMovimenti : string = "competenza";

  tempTipoElencoMovimenti : string = this.tipoElencoMovimenti;

  searchInProgress : boolean = false;

  /**
   * variabile utilizzata per nominare le colonne della tabella a seconda del tipo di movimenti che si vuole visualizzare
   *
   * - competenza;
   * - conoscenza;
   */
  theadNameColumn : any = {
    competenza : ["Operazione", "Data", "Ora", "Da", "A", "Motivo", "Eseguito da"],
    conoscenza : ["Dal", "Al", getLabelAgd()+"-"+getLabelUo(), "Eseguito da"]
  }

  mapNameController : any = {};

  mapNameDocumento : any = {
    competenza : "movimentiCompetenzaDocumento",
    conoscenza : "movimentiConoscenzaDocumento"
  }

  mapNameFascicolo : any = {
    competenza : "movimentiCompetenzaFascicolo",
    conoscenza : "movimentiConoscenzaFascicolo"
  }

  listaMovimenti : any = [];

  constructor(private requestService : RequestService, private resizeSvc: ResizeService) {
    this.size = this.resizeSvc.onResize();
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }

  ngOnInit(): void {
    if(this.objParameterComponent != null)
    {
      if(this.objParameterComponent.fascicoloMode){
        this.fascicoliMode = true;
        this.fascicolo = this.objParameterComponent.fascicolo;
        this.docNumString = this.objParameterComponent.docNumString;
      }
      else
      {
        this.dettaglioDocumentoRequest = this.objParameterComponent.dettaglioDocumentoRequest;
        this.docNumString = this.objParameterComponent.docNumString;
      }
    }

    this.mapNameController = this.fascicoliMode ? this.mapNameFascicolo : this.mapNameDocumento

    this.getListaMovimenti();
  }

  getListaMovimenti()
  {

    let payload = this.fascicoliMode ? this.fascicolo : this.dettaglioDocumentoRequest;

    this.searchInProgress = true;
    this.tipoElencoMovimenti = this.tempTipoElencoMovimenti;
    this.requestService.postRequest(this.mapNameController[this.tipoElencoMovimenti], payload,
      (response) => {
        this.listaMovimenti = [];
        this.listaMovimenti = response.obj.list;
        this.searchInProgress = false;

      },
      () => {
        this.searchInProgress = false;
        alert("qualcosa non va");
      }
    );

  }

}
