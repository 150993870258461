  <!-- Modal -->
  <div class="modal fade viewerDocumentiModal" id="{{idViewer}}" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">

          <div class="titolo-viewer d-flex" [ngClass]="{'flex-column breadcrumb-mobile' : size < 3}">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" *ngIf="!showObjViewer.showAllegato" (click)="showObjViewer.setAllegato()"><i  class="icon-left-big text-primary-archipro cursor-pointer" title="Torna indietro"></i></h5>
                <div class="d-flex flex-column">
                  <h5 class="modal-title" [ngClass]="{'cursor-pointer text-primary-archipro' : !showObjViewer.showAllegato}" (click)="showObjViewer.setAllegato()">
                     <span *ngIf="documentoInfo.annullato" class="text-danger font-weight-bold">(Annullato)</span> {{allegatoVisualizzato.descrizione}}
                  </h5>
                  <span *ngIf="allegatoVisualizzato.converted" class="modalTitlePDFConverted">Copia in formato PDF del file</span>
                </div>
              </div>


            <!--Breadcrumb Movimenti-->
            <div class="d-flex align-items-center" *ngIf="showObjViewer.showMovimenti">
                <span style="font-size: 26px;margin: 0 12px;">></span>
                <h5 class="modal-title" [ngClass]="{'breadcrumb-mobile' : size < 3}">
                    Elenco movimenti
                </h5>
            </div>

            <!--Breadcrumb Movimenti-->
            <div class="d-flex align-items-center" *ngIf="showObjViewer.showDettaglioDoc">
                <span style="font-size: 26px;margin: 0 12px;">></span>
                <h5 class="modal-title" [ngClass]="{'breadcrumb-mobile' : size < 3}">
                    Dettagli documento
                </h5>
            </div>

            <!--Breadcrumb Firmatario-->
            <div class="d-flex align-items-center" *ngIf="showObjViewer.showDatiFirma">
                <span style="font-size: 26px;margin: 0 12px;">></span>
                <h5 class="modal-title" [ngClass]="{'breadcrumb-mobile' : size < 3}">
                    Dettagli firmatario
                </h5>
            </div>

            <!--Breadcrumb Invio email-->
            <div class="d-flex align-items-center cursor-pointer" *ngIf="showObjViewer.showInvioEmail" (click)="showObjViewer.setShowInvioEmail()">
              <span style="font-size: 26px;margin: 0 12px;">></span>
              <h5 class="modal-title" [ngClass]="{'breadcrumb-mobile' : size < 3}">
                  Invio email
              </h5>
          </div>

            <div class="d-flex align-items-center cursor-pointer" *ngIf="showObjViewer.showInvioEmail && showObjViewer.showAllegatoEmail">
              <span style="font-size: 26px;margin: 0 12px;">></span>
              <h5 class="modal-title" [ngClass]="{'breadcrumb-mobile' : size < 3}">
                  {{allegatoEmail.fileOrig}}
              </h5>
            </div>

            <!--Breadcrumb Pubblicazione albo-->
            <div class="d-flex align-items-center" *ngIf="showObjViewer.showPubblicazioneAlbo">
              <span style="font-size: 26px;margin: 0 12px;">></span>
              <h5 class="modal-title" [ngClass]="{'breadcrumb-mobile' : size < 3}">
                  Pubblicazione albo ({{documentoInfo.docNumString}})
              </h5>
          </div>

            <!--Breadcrumb Annulla protocollo-->
            <div class="d-flex align-items-center" *ngIf="showObjViewer.showAnnullaProtocollo">
                <span style="font-size: 26px;margin: 0 12px;">></span>
                <h5 class="modal-title" [ngClass]="{'breadcrumb-mobile' : size < 3}">
                    Annulla protocollo ({{documentoInfo.docNumString}})
                </h5>
            </div>

            <!--Breadcrumb Flussi-->
            <div class="d-flex align-items-center" *ngIf="showObjViewer.showFlussi">
                <span style="font-size: 26px;margin: 0 12px;">></span>
                <h5 [ngClass]="{'breadcrumb-mobile' : size < 3}" class="modal-title" (click)="showObjViewer.setFlussi()" [ngClass]="{'cursor-pointer text-primary-archipro' : showObjViewer.showDettaglioEmail}">
                    Flussi
                </h5>
                <div class="d-flex align-items-center" *ngIf="showObjViewer.showDettaglioEmail">
                    <span style="font-size: 26px;margin: 0 12px;">></span>
                    <h5 class="modal-title" [ngClass]="{'breadcrumb-mobile' : size < 3}">
                        Dettaglio email
                    </h5>
                </div>
            </div>

          </div>
          <button *ngIf="!extViewer" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body overflow-auto" [ngClass]="{'bg-form-color' : showObjViewer.showInvioEmail || showObjViewer.showPubblicazioneAlbo}">
            <app-loading *ngIf="loading" [text]="''"></app-loading>

            <app-elenco-movimenti *ngIf="!loading && showObjViewer.showMovimenti"
            [objParameterComponent]="objParameterComponent"
            ></app-elenco-movimenti>

            <app-form-albo-pretorio *ngIf="!loading && showObjViewer.showPubblicazioneAlbo"
              (returnEvent)="showObjViewer.setAllegato()"
              [documentoInfo]="documentoInfo"
            ></app-form-albo-pretorio>


            <div *ngIf="!loading && showObjViewer.showAllegato"  style="height: 99%;">
                <ng2-pdfjs-viewer *ngIf="!isZip(allegatoVisualizzato.url); else zipFile"
                    pdfSrc="{{allegatoVisualizzato.url}}"
                ></ng2-pdfjs-viewer>
                <ng-template #zipFile>
                  <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                    <h4 class="text-center">Anteprima documento non disponibile per la tipologia selezionata.</h4>
                  </div>
                </ng-template>
                <div *ngIf="errorDocument" class="h-100 w-100 d-flex justify-content-center align-items-center">
                    <h4 class="text-center">{{textError}}</h4>
                </div>
            </div>


            <app-dettaglio-documento-viewer *ngIf="!loading && showObjViewer.showDettaglioDoc"
            (stampaEtichetta)="stampaEtichetta()"
            (stampaEtichettaGenericaPerAllegato)="stampaEtichettaGenericaPerAllegato($event)"
            [showStampaGenerica]="showStampaGenerica"
            [allegato]="allegatoVisualizzato"
            [documentoInfo]="documentoInfo"
            [objCompetenza]="objCompetenza"
            [elencoCC]="elencoCC"
            [idViewer]="idViewer"
            ></app-dettaglio-documento-viewer>

            <app-firmatario *ngIf="!loading && showObjViewer.showDatiFirma"
            [listaFirmatari]="allegatoVisualizzato.signer"
            ></app-firmatario>

            <!--<app-dettaglio-email *ngIf="!loading && showObjViewer.showFlussi" [mail]="mail"></app-dettaglio-email>-->
            <app-interoperabilita *ngIf="!loading && showObjViewer.showFlussi && !showObjViewer.showDettaglioEmail"
            [openMailViewer]="openMailViewer"
            [pk]="documentoInfo.pk"
            ></app-interoperabilita>

            <app-dettaglio-email *ngIf="!loading && showObjViewer.showFlussi && showObjViewer.showDettaglioEmail" [pkFlussoMail]="pkFlussoMail"></app-dettaglio-email>

            <app-elimina-protocollo *ngIf="!loading && showObjViewer.showAnnullaProtocollo" [objParameterComponent]="objAnnulla"></app-elimina-protocollo>

            <app-invio-email *ngIf="!loading && documentoInfo.pk && listaAllegati" [hidden]="!(showObjViewer.showInvioEmail && !showObjViewer.showAllegatoEmail)"
                [listaAllegati]="listaAllegati"
                [documentoInfo]="documentoInfo"
                (showAllegato)="showAllegatoEmailSelezionato($event)"></app-invio-email>

            <div *ngIf="!loading && showObjViewer.showInvioEmail &&  showObjViewer.showAllegatoEmail"  style="height: 99%;">
              <ng2-pdfjs-viewer
                  pdfSrc="{{allegatoEmail.url}}"
              ></ng2-pdfjs-viewer>
            </div>

        </div>
        <div  *ngIf="!showObjViewer.showInvioEmail" class="modal-footer d-flex justify-content-around icon-viewer">

            <span (click)="showObjViewer.setDettaglioDoc()" title="Dettagli" class="d-flex flex-column text-center" [ngClass]="{'text-primary-archipro font-weight-bold' : showObjViewer.showDettaglioDoc}">
                <i class="icon-info"></i>
                <span *ngIf="size>2">Dettagli</span>
            </span>

            <!--Firmatario NEw-->
            <span (click)="allegatoVisualizzato.signer != null && showObjViewer.setDatiFirma()" *ngIf="isFirmaDigitale && size>2" title="Download" class="d-flex flex-column text-center"
                [ngClass]="{'disabled text-icon-disabled cursor-not-allowed' : allegatoVisualizzato.signer == null, 'text-primary-archipro font-weight-bold' : showObjViewer.showDatiFirma}">
                <i class="icon-{{isFirmaDigitale ? 'doc-certificate' : 'pencil'}}"></i>
                <span *ngIf="size>2">Firmatario</span>
            </span>

            <!--Firmatario-->
            <div class="dropdown" *ngIf="!isFirmaDigitale">
                <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{'disabled text-icon-disabled cursor-not-allowed' : allegatoVisualizzato.signer == null }">
                    <div class="d-flex ">
                        <div class="d-flex flex-column text-center">
                            <i class="icon-{{isFirmaDigitale ? 'doc-certificate' : 'pencil'}}"></i>
                            <span  *ngIf="size>2">Firmatario</span>
                        </div>
                    </div>
                </span>

                <div class="dropdown-menu" *ngIf="allegatoVisualizzato.signer != null">
                  <!-- Dropdown menu links -->
                    <h6 class="dropdown-header font-weight-bold pb-0">Info Firmatario</h6>
                    <div class="dropdown-divider"></div>
                        <div class="dropdown-information d-flex flex-column" *ngFor="let firmatario of allegatoVisualizzato.signer; let i = index">
                            <div class="righe-info d-flex justify-content-between mt-1">
                                <span><b>Firmatario:</b> {{firmatario.descrizione}} </span>
                            </div>
                        </div>
                </div>
            </div>

            <!--Riferimenti-->
            <div class="dropdown" *ngIf="false">
                <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{'disabled text-icon-disabled' : (documentoInfo.riferimenti == null || documentoInfo.riferimenti.length == 0) }">
                    <div class="d-flex ">
                        <div class="d-flex flex-column text-center">
                            <i class="icon-link"></i>
                            <span  *ngIf="size>2">Riferimenti</span>
                        </div>
                    </div>
                </span>

                <div class="dropdown-menu" *ngIf="!(documentoInfo.riferimenti == null || documentoInfo.riferimenti == undefined || documentoInfo.riferimenti.length == 0)">
                  <!-- Dropdown menu links -->
                  <h6 class="dropdown-header font-weight-bold pb-0">Riferimenti</h6>
                  <div class="dropdown-divider"></div>
                    <div class="dropdown-information d-flex flex-column" *ngFor="let riferimento of  documentoInfo.riferimenti; let i = index">

                        <div class="righe-info d-flex justify-content-between mt-1">
                            <span><b>Tipo:</b> {{riferimento.tipoRiferimento == 'P' ? 'Pratica' : 'Ente'}}</span>
                        </div>

                        <div *ngIf="riferimento.tipoRiferimento == 'P'" class="righe-info d-flex justify-content-between mt-1">
                            <span><b>Pratica:</b> {{riferimento.numPratica}} / {{riferimento.annoPratica}} </span>
                        </div>

                        <div class="righe-info d-flex justify-content-between mt-1">
                            <span><b>Ente:</b> {{riferimento.enteRiferimento}}
                                <span *ngIf="riferimento.tipoRiferimento == 'E'">
                                    {{riferimento.enteVia != null ? riferimento.enteVia : '' }}
                                    {{riferimento.enteDecanato != null ? riferimento.enteDecanato : '' }}
                                </span>
                                ({{riferimento.enteComune != null ? riferimento.enteComune : '' }})
                            </span>
                        </div>

                        <div *ngIf="riferimento.tipoRiferimento == 'P'" class="righe-info d-flex justify-content-between mt-1">
                            <span><b>Referente:</b> {{riferimento.referentePratica}} </span>
                        </div>

                        <div *ngIf="riferimento.tipoRiferimento == 'P'" class="righe-info d-flex justify-content-between mt-1">
                            <span><b>Oggetto:</b> {{riferimento.oggettoPratica}} </span>
                        </div>

                        <div class="dropdown-divider mt-4 mb-0" *ngIf=" documentoInfo.riferimenti.length > 1 && i <  documentoInfo.riferimenti.length - 1"></div>
                    </div>
                </div>
            </div>

            <!--Allegati -->
            <div class="dropdown" class="d-flex flex-column text-center">


                <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="d-flex ">
                        <div class="d-flex flex-column text-center">
                            <i class="icon-attach"></i>
                            <span  *ngIf="size>2">Allegati</span>
                        </div>
                    </div>
                </span>

                <div class="dropdown-menu">
                    <h6 class="dropdown-header font-weight-bold pb-0">Allegati</h6>
                    <div class="dropdown-divider"></div>
                <!-- Dropdown menu links -->
                  <div  *ngFor="let allegato of listaAllegati"  class="d-flex justify-content-between dropdown-item">
                    <a class="text-decoration-none cut-text text-dark" [ngClass]="{'font-weight-bold' : allegato.pk.progr == allegatoVisualizzato.pk.progr}"
                      (click)="showObjViewer.setAllegato();getInfoAllegato(allegato)">
                      {{allegato.descrizione}}
                    </a>
                    <ng-container *ngIf="showStampaGenerica">
                      <div class="composed-icon-pedice cursor-pointer text-primary-archipro" (click)="stampaEtichettaGenericaPerAllegato(allegato)">
                        <i class="icon-print"></i>
                        <i class="icon-stamp-solid pedice" style="font-size: 14px;"></i>
                      </div>
                    </ng-container>


                  </div>
                </div>
            </div>

            <span *ngIf="size>2 && false" (click)="showObjViewer.setMovimenti()" title="Movimenti" class="d-flex flex-column text-center" [ngClass]="{'text-primary-archipro font-weight-bold' : showObjViewer.showMovimenti}">
                <i class="icon-switch"></i>
                <span *ngIf="size>2">Movimenti</span>
            </span>

            <span *ngIf="size > 2 && false" (click)="(documentoInfo.flussiInterop || documentoInfo.flussiMail) && showObjViewer.setFlussi()" title="Flussi" class="d-flex flex-column text-center" [ngClass]="{'text-primary-archipro font-weight-bold' : showObjViewer.showFlussi, 'disabled text-icon-disabled cursor-not-allowed' : !(documentoInfo.flussiInterop || documentoInfo.flussiMail)}">
                <i class="icon-spin3"></i>
                <span *ngIf="size > 2">Flussi</span>
            </span>

            <a *ngIf="size>2" title="Download"
              class="d-flex flex-column text-center text-dark"
              [href]="allegatoVisualizzato.url" download>
                <i class="icon-download"></i>
                <span *ngIf="size>2">Download</span>
            </a>


            <span *ngIf="false" title="Trasmetti" (click)="trasmettiDocumento()" [ngClass]="{'disabled text-icon-disabled cursor-not-allowed' : !documentoInfo.security.possoTrasmettereDocumento}" class="d-flex flex-column text-center">
                <i class="icon-export"></i>
                <span *ngIf="size>2">Trasmetti</span>
            </span>



            <!--Azioni && size <= 2-->
            <div  *ngIf="!extViewer" class="dropdown" class="d-flex flex-column text-center">


                <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="d-flex ">
                        <div class="d-flex flex-column text-center">
                            <i class="icon-ellipsis-vert"></i>
                            <span  *ngIf="size>2">Azioni</span>
                        </div>
                    </div>
                </span>

                <div class="dropdown-menu text-primary-archipro">
                <!-- Dropdown menu links -->
                <h6 class="dropdown-header font-weight-bold pb-0">Azioni</h6>
                <div class="dropdown-divider"></div>
                    <a *ngIf="showApprovaDoc && documentoInfo.statoNP == 1" class="dropdown-item" (click)="approva()"><i class="icon-ok"></i>Approva</a>
                    <a *ngIf="isDocNp" class="dropdown-item" (click)="protocollaDaNp()"><i class="icon-stamp-solid"></i>Protocolla</a>
                    <a *ngIf="documentoInfo.security.possoModificareDocumento  && !documentoInfo.annullato" class="dropdown-item" (click)="modificaProtocollo()"><i class="icon-edit "></i>Modifica</a>

                    <a class="dropdown-item d-flex align-items-center" (click)="!showStampaGenerica ? stampaEtichetta() : stampaEtichettaGenericaPerAllegato(allegatoVisualizzato)">
                      <div class="composed-icon-pedice cursor-pointer">
                        <i class="icon-print"></i>
                        <i *ngIf="showStampaGenerica" class="icon-stamp-solid pedice" style="font-size: 14px;"></i>
                      </div>
                      Stampa etichetta
                    </a>

                    <ng-container *ngIf="globalService?.GLOBAL?.PRO_INVIA_CUSTOM_MAIL">
                      <a *appPermission="'inviaProtocolloEmail'" class="dropdown-item" (click)="showObjViewer.setShowInvioEmail()"><i class="icon-mail"></i>Invia Doc. per mail</a>
                    </ng-container>


                    <a class="dropdown-item" [ngClass]="{'disabled' : !documentoInfo.security.possoTrasmettereDocumento}" (click)="trasmettiDocumento()"><i class="icon-export "></i>Trasmetti</a>
                    <a *ngIf="user.isAg" class="dropdown-item" (click)="annullaProtocollo()"><i class="icon-cancel"></i>Annulla protocollo</a>
                    <a *appPermission="'multiFascicolazione'" [ngClass]="{'disabled' : !documentoInfo.descrTitolario}" class="dropdown-item" (click)="goToMultifascicolazione()"><i class="icon-docs"></i>Multifascicolazione</a>
                    <a class="dropdown-item" (click)="showObjViewer.setFlussi()"><i class="icon-{{showObjViewer.showFlussi ? 'doc' : 'spin3'}}"></i>{{showObjViewer.showFlussi ? 'Documento' : 'Flussi'}}</a>
                    <a class="dropdown-item" (click)="showObjViewer.setMovimenti()"><i class="icon-{{showObjViewer.showMovimenti ? 'doc' : 'switch'}}"></i>{{showObjViewer.showMovimenti ? 'Documento' : 'Movimenti'}}</a>
                    <a *ngIf="false" class="dropdown-item" (click)="showObjViewer.setPubblicazioneAlbo()"><i class="icon-doc-certificate"></i>Pubblicazione albo</a>
                    <a class="dropdown-item" [href]="allegatoVisualizzato.url" download><i class="icon-download"></i>Download</a>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
