import { Component, HostListener } from '@angular/core';
import { ResizeService } from './service/resize/resize.service';
import { SCREEN_SIZE } from './service/resize/screen-size.enum';
import { AlertService } from './service/alertservice/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

constructor(public alertService : AlertService){}
/*
  static size : SCREEN_SIZE;

  constructor(private resizeSvc: ResizeService){
    AppComponent.size = this.resizeSvc.onResize();
  }


  @HostListener("window:resize", [])
  private onResizeFunction() {
    AppComponent.size = this.resizeSvc.onResize();
  }*/
}
