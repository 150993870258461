import { Collocazione } from "./Collocazione";
import { ObjCompetenzaServer } from "./ObjCompetenza";
import { PrimaryKeyFascicolo } from "./PrimaryKeyFascicolo";
import { Titolario } from "./Titolario";

export class Fascicolo extends PrimaryKeyFascicolo{

    titolario : Titolario;
    collocazione : Collocazione;

    progrAnno : number;
    descrTitolario : string;
    descrCollocazione : string;
    dataAperturaFascicolo : string;
    oggetto : string;
    soggetto : string;
    codAgd : number;
    codUo : number;
    descrizioneAgd : string;
    descrizioneUo : string;
    numFascVis : string;

    constructor(obj?){
        super(obj? obj : null);
        if(obj){
            this.titolario = new Titolario(obj.titolario);
            this.collocazione = new Collocazione(obj.collocazione);
            this.progrAnno = obj.progrAnno;
            this.descrTitolario = obj.descrTitolario;
            this.descrCollocazione = obj.descrCollocazione;
            this.numeroFascicolo = obj.numeroFascicolo;
            this.annoFascicolo = obj.annoFascicolo;
            this.dataAperturaFascicolo = obj.dataAperturaFascicolo;
            this.oggetto = obj.oggetto;
            this.soggetto = obj.soggetto;
            this.codAgd = obj.codAgd;
            this.codUo = obj.codUo;
            this.descrizioneAgd = obj.descrizioneAgd;
            this.descrizioneUo = obj.descrizioneUo;
            this.numFascVis = obj.numFascVis;
        }
        else
        {
            this.titolario = new Titolario();
            this.collocazione = new Collocazione();
        }
    }

    buildFromEstratti(obj : any) : Fascicolo{

      let [titolo1, titolo2, titolo3, titolo4, titolo5, titolo6] = obj.titolario.split(".");

      this.titolario = new Titolario({titolo1 : titolo1, titolo2 : titolo2, titolo3 : titolo3, titolo4 : titolo4, titolo5 : titolo5, titolo6 : titolo6})
      this.oggetto = obj.oggetto;
      this.soggetto = obj.soggetto;
      this.numFascVis = obj.numFascVis;
      this.annoFascicolo = obj.fascPK.annoFascicolo;
      this.numeroFascicolo = obj.fascPK.numFascicolo;
      this.dataAperturaFascicolo = obj.dataApertura;

      return this;
    }

    /**
     * Metodo che setta la competenza del fascicolo in base a un oggetto che viene tornato dal server
     * (fatto perchè gli oggetti di competenza sono sempre diversi)
     * @param objCompetenzaServer
     */
    setCompetenzaFromObjCompetenzaServer(objCompetenzaServer : ObjCompetenzaServer) : void{
        this.descrizioneAgd = objCompetenzaServer.descrAgd;
        this.descrizioneUo = objCompetenzaServer.descrUo;
        this.codAgd = objCompetenzaServer.codiceAgd;
        this.codUo = objCompetenzaServer.codiceUo;
    }

    /**
     * Funzione che ritorna la stringa della competenza del fascicolo
     * @returns
     */
    getDescrFascicoloInCarico() : string
    {
        return this.descrizioneAgd + " (" + this.codAgd + ") " + (this.uoIsNotNull() ? this.descrizioneUo + " (" + this.codUo + ")" : "");
    }

    /**
     * Funzione che ritorna numero e anno fascicolo in stringa separato da /
     * @returns
     */
    getNumString() : string{
        //return this.numeroFascicolo + "/" + this.annoFascicolo;
        return this.numFascVis + "/" + this.annoFascicolo;
    }

    /**
     * Funzione che controlla se la uo è specificato e costruisce la stringa di getDescrFascicoloInCarico() di conseguenza
     * @returns
     */
    uoIsNotNull() : boolean{
        return this.descrizioneUo != null && this.codUo != 0;
    }

    /**
     * Funzione che restituisce per intero tutto il titolario e il numero e anno fascicolo come stringa
     * @returns A.B.C.D.E.F/XX/AAAA
     */
    getFullTitolarioAndFascicoloString() : string{
        return this.titolario.getFullTitolarioValue() + "/" + this.getNumString();
    }
}
